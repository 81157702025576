:root {
  --color-brand-purple: #352958;
  --color-brand-purple-light: #696ef3;
  --color-brand-pink: #f23b67;
  --color-brand-gray-bg: #444444;
  --color-brand-white: #ffffff;

  --color-illustrations-light-purple: #9795b1;
  --color-illustrations-black-primary: #151515;
  --color-illustrations-black-bg: #1c1c1c;
  --color-illustrations-dark-gray: #292929;
  --color-illustrations-gray: #575757;
  --color-illustrations-gray-2: #8c8c8c;
  --color-illustrations-light-gray: #d9d9d9;

  --button-slider-default-background: #212121;
  --button-slider-disabled-foreground: #484848;

  --illustrations-purple: #696ef3;

  --card-styles-purple-bg: #e8e8fe4d;
  --card-styles-light-gray2-bg: #f8f8f8;

  --color-text-main: #ffffff;
  --color-text-description: #9f9f9f;
  --color-text-subtitle: #bfbfbf;
  --color-text-accent: #f23b67;
  --color-text-secondary: #d1d1d1;
  --color-text-disabeld: #424141;
  --color-text-secondary-2: #555555;
  --color-text-error: #ff7274;

  --button-outline-text-black: #010101;

  --icon-button-default-background: #eeecec33;
  --icon-button-default-border: #eeecec;

  --color-button-tertiary-bg: #151515;
  --color-button-tertiary-bg-transparent: #15151599;
  --color-button-tertiary-bg-hover: #232323;
  --color-button-tertiary-bg-press: #323232;

  --color-bread-crumbs-text-active: #adadad;
  --color-bread-crumbs-separator: #8d8d8d;
  --color-bread-crumbs-text: #7e7e7e;

  --color-light-purple-bg: #f3f2f4;

  --input-default-backround: #ffffff26;
  --select-active-background: #e7e7e7;
  --gradient-pink-purple-default: linear-gradient(
    90deg,
    #f23b67 -15.5%,
    #25125e 116%
  );
  --gradient-pink-purple-hover: linear-gradient(
    73.41deg,
    #f23b67 10.73%,
    #25125e 130.23%
  );
  --gradient-pink-purple-press: linear-gradient(
    76.13deg,
    #f23b67 -12.24%,
    #25125e 112.37%
  );
  --gradient-pink-purple-form: linear-gradient(
    90deg,
    #b62f64 0.59%,
    #4f1a5f 100%
  );

  --gradient-main-circle: linear-gradient(
    180deg,
    rgba(242, 59, 103, 0.4) 0%,
    rgba(140, 34, 60, 0) 100%
  );
  --gradient-main-circle-disabled: linear-gradient(
    180deg,
    rgba(242, 59, 103, 0.18) 0%,
    rgba(140, 34, 60, 0) 100%
  );

  --gradient-text-quote-line: linear-gradient(0deg, #f23b67 0%, #25125e 100%);
  --pagination-button-color: #454545;

  --article-background-color: #222222;

  --color-button-accordion-active: #2f2f2f;

  --color-card-caption: #c2becd;
  --icon-button-default-background: #c2c2c233;

  --color-stroke: #9a9a9a;

  --dialog-overlay-background: #00000099;

  --file-background: #dfdfdf;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-main);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  max-width: 100vw;
  width: 100%;
  font-size: 16px;
  height: 100%;
  font-weight: 400;
  min-height: 100%;
}

html {
  scrollbar-gutter: stable !important;
}

html > body {
  scrollbar-gutter: stable !important;
  font-family: var(--font-main);
  color: var(--color-brand-white);
  background: var(--color-illustrations-black-primary);
  overflow-x: hidden;
  max-width: 1440px;
  margin: auto;
  margin-right: auto !important;
}

html > body[data-scroll-locked] {
  padding: 0 !important;
  margin-right: auto !important;
  margin: auto !important;
}

html > body[data-scroll-locked="1"] {
  padding: 0 !important;
  margin-right: auto !important;
  margin: auto !important;
}

main {
  flex-grow: 1;
}

header {
  margin-top: 1.406rem;
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

footer {
  border-top: 1px solid var(--color-illustrations-gray);

  margin-top: 6.25rem;
  padding-bottom: 3.75rem;

  padding-top: 3.75rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

a {
  color: var(--color-brand-white);
  text-decoration: none;
}

@media only screen and (min-width: 1440px) {
  body {
    max-width: 1440px;
  }
  header {
    padding-bottom: 1.406rem;
  }
  footer {
    padding-left: 10.313rem;
    padding-right: 10.313rem;
    margin-bottom: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  body {
    overflow-x: hidden;
  }

  header {
    margin-top: 1.25rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  footer {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  header {
    margin-top: 1.25rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--color-illustrations-gray);
  }

  footer {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-bottom: 1.875rem;
  }
}
